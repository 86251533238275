import React from 'react';

/* TODO: add email button */
/* TODO: add a form */

const Contact = () => {
  return (
    <div>
      <h1>Contact</h1>
      <h2>Have any questions or feedback? We'd love to hear it.</h2>
    </div>
  );
};

export default Contact;
