import React from 'react';
import Intro from './Intro/Intro';
import How from './How/How';
import Contact from './Contact/Contact';

/* TODO: See it in action! */
/* TODO: extract screen size */

const LandingPage = () => {
  return (
    <div>
      <Intro />
      <How />
      <Contact />
    </div>
  );
};

export default LandingPage;
