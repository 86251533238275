import React from 'react';

const How = () => {
  return (
    <div>
        <h1>How it works</h1>
        <p>Get started with your portable visionOS speaker in three easy steps!</p>
        <h2>Sign into Spotify</h2>
        <p>The speaker will change colors once you've signed in.</p>
        <h2>Play your favorite songs</h2>
        <p>Open the Spotify app on another device or browser and start playing music.</p>
        <h2>Control the speaker</h2>
        <p>Adjust playback and switch songs with the controls below the speaker.</p>
    </div>
  );
};

export default How;
