import React from 'react';
import './Intro.css';

const Intro = () => {
  return (
    <div class="intro">
      <div class="intro-container">
        <p class="intro-title">BeatBulb</p>
        <p class="intro-subtitle">A new dimension for your playlist</p>
        <p class="intro-feedback">Got feedback? Share it here!</p>
      </div>
    </div>
  );
};

export default Intro;
